Ycc.MultiAutocomplete = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function() {
    var placeholder = self.el.data('placeholder');
    var allowClear = self.el.data('allow-clear') === false ? false : true;
    var closeOnSelect = self.el.data('close-on-select') === false ? false : true;

    // See https://stackoverflow.com/a/49953496
    if (allowClear && !placeholder) {
      placeholder = '';
    }

    options = {
      allowClear: allowClear,
      placeholder: placeholder,
      closeOnSelect: closeOnSelect,
      theme: 'bootstrap4'
    };

    // 添加投资机构的时候，显示类似名字的机构，给创建人参考
    if (self.el.data('allow-user-input-no-investor-notice')) {
      options = $.extend(options, {
        language: {
          noResults: function() { return '' }
        },
        ajax: {
          processResults: function (data) {
            var processData = data.results.map(function(d){
              d['disabled'] = true
              return d
            })
            if (data.results.length > 0 ){
              return {
                results: [{
                  text: '数据库内类似名称机构(已存在不用重复添加):',
                  children: processData
                }]
              }
            } else {
              return { results: [] }
            }
          }
        }
      });
    }

    if (self.el.data('allow-user-input')) {
      options = $.extend(options, {
        tags: true,
        createTag: function (params) {
          return {
            id: params.term,
            text: params.term,
            newOption: true
          }
        }
      });
    }

    // 插入到最后一个
    if (self.el.data('allow-user-input-order-last')) {
      options = $.extend(options, {
        insertTag: function (data, tag) {
          // Insert the tag at the end of the results
          data.push(tag);
        }
      });
    }

    // 这个是"添加投资人"页面单独用的, 文字稍作变化
    if (self.el.data('allow-user-input-new-investor')) {
      options = $.extend(options, {
        tags: true,
        createTag: function (params) {
          if (params.term && params.term.trim() != '') {
            var p = {
              id: params.term,
              text: `将机构 "${params.term}" 添加到数据库`,
              newOption: true
            }
            console.log(p);
            return p
          }
        }
      });
    }

    if (self.el.data('no-results-html')) {
      options = $.extend(options, {
        language: {
          noResults: function() { return self.el.data('no-results-html'); }
        },
        escapeMarkup: function(markup) { return markup; }
      });
    }

    self.el.select2(options);
  };

  self.init(el);

  return self;
});
