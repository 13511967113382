// CommentsContainer 用于 build 里的评论功能 （如果我没看错的话）
Ycc.CommentsContainer = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function() {
    self.initElements();
    self.initEvents();
  };

  self.initElements = function() {
    self.newCommentForm = self.el.find('[data-role=new_comment_form]');
  };

  self.initEvents = function() {
    self.el.find('.delete-link').on('click', self.onDeleteLinkClick);
    self.el.find('.edit-link').on('click', self.onEditLinkClick);
    self.el.find('[data-role=edit_comment_form]').on('submit', self.onEditCommentFormSubmit);
    self.newCommentForm.on('submit', self.onNewCommentFormSubmit);
  };

  self.rerender = function(response) {
    var containerId = self.el.attr('id');
    self.el.replaceWith(response);
    self.el = $('#' + containerId);
    self.render();
    Ycc.renderElements(self.el);
    self.el.find('textarea').focus();
  };

  self.onDeleteLinkClick = function() {
    var link = $(this);
    $.ajax({
      url: link.attr('href'),
      type: 'delete',
      success: function(response) {
        self.rerender(response);
      }
    });
    return false;
  };

  self.onEditLinkClick = function() {
    var link = $(this);
    var commentEl = link.closest('[data-role=comment');
    var showCommentEl = commentEl.find('[data-role=show_comment_container]');
    var editCommentEl = commentEl.find('[data-role=edit_comment_container]');
    showCommentEl.hide();
    editCommentEl.show();
    return false;
  };

  self.onEditCommentFormSubmit = function() {
    var form = $(this);
    $.ajax({
      url: form.attr('action'),
      type: 'patch',
      data: form.serialize(),
      success: function(response) {
        self.rerender(response);
      }
    });
    return false;
  };

  self.onNewCommentFormSubmit = function() {
    $.ajax({
      url: self.newCommentForm.attr('action'),
      type: self.newCommentForm.attr('method'),
      data: self.newCommentForm.serialize(),
      success: function(response) {
        self.rerender(response);
      }
    });
    return false;
  };

  self.init(el);

  return self;
});
