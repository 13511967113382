// 标签系统 (主要依靠 Tagify 实现)
Ycc.Tag = (function (el) {
  var self = this;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  self.init = function (el) {
    self.el = el;
    self.render();
  };

  self.render = async function () {
    var element = self.el[0];
    self.tagify = new Tagify(element, {
      dropdown : {
        maxItems: 15,
      }
    })
    self.tagify.on('input', self.onInput)

    // 审阅申请页
    if (self.el.data('evaluate-application')) {
      await self.loadExistingTags(); // 载入标签
      // 注意一定要先载入标签，再设置 onChange, 顺序不能错，否则会有问题
      // 假设顺序反过来，先 onChange，再载入标签
      // 载入标签时调用 addTags 方法会触发 onChange
      // 比如用户添加了 "北京" "上海" "广州" "深圳" 四个标签
      // 刷新页面后，载入标签时，addTags "北京" 会触发 onChange
      // onChange 会保存标签，所以保存了 "北京" （另外3个就丢失了）
      // 而在用户的视角看来，明明加了4个标签，一刷新，只剩下第1个标签还在，其他都没了
      // element.addEventListener('change', self.onChange) // onChange 已经拆分成 onAdd 和 onRemove
      self.tagify.on('focus', self.onFocus)
      self.tagify.on('add', self.onAdd)
      self.tagify.on('remove', self.onRemove)
      self.tagify.on('edit:updated', self.onEditUpdated)
      self.dropdownScroll();
    }

    // 搜索申请页
    if (self.el.data('filter-application')) {
      self.loadTagFromURL()
      self.tagify.on('focus', self.onFocus)
    }
  };

  // 确保页面滚动时, Tagify 的 dropdown 不要错位
  self.dropdownScroll = function () {
    document.addEventListener('scroll', function (e) {
      let tagify = self.tagify
      tagify.dropdown.position.call(tagify)
      // 以上写法来自于:
      // https://github.com/yairEO/tagify/issues/314
      // https://github.com/yairEO/tagify/issues/400
    });
  }

  // 自动补全
  self.onInput = async function (e) {
    let tagify = self.tagify

    tagify.settings.whitelist.length = 0; // reset current whitelist
    tagify.loading(true) // show the loader animation

    var url = '/evaluation/tags/autocomplete'
    var search_term = '' // 搜索词（允许为空）

    // 有时会不传参数来调用这个函数，所以这样写
    if (e && e.detail && e.detail.value) {
      search_term = e.detail.value;
    }

    var response = await $.ajax({
      url: url,
      type: "GET",
      data: {
        q: search_term
      }
    });
    if (response.status == 'success') {
      var data = response.data
      tagify.settings.whitelist = data.concat(tagify.value);
      await sleep(100);
      // 支持拼音搜索不传入 search_term
      tagify.loading(false).dropdown.show.call(tagify, '');
    } else {
      tagify.loading(false).dropdown.hide.call(tagify)
    }
  }

  // 从 URL 中读取标签，并放到界面上
  self.loadTagFromURL = function () {
    const query = new URLSearchParams(window.location.search);
    var result = query.get("tag_search")
    if (result != null) {
      self.tagify.addTags(result)
    }
    // 然后增加监听事件
    var element = self.el[0];
    element.addEventListener('change', self.onFilterChange)
  }

  // 当搜索页的标签变化时
  self.onFilterChange = function (e) {
    var tag_array = []

    // 处理值, 弄成一个数组
    var string_value = e.target.value
    if (string_value != "") {
      var json = JSON.parse(string_value)
      tag_array = json.map(a => a.value);
    }

    // 操作 URL 里的 get 参数
    const query = new URLSearchParams(window.location.search);
    if (tag_array.length == 0) {
      query.delete("tag_search")
    } else {
      query.set("tag_search", tag_array.join(','));
    }
    location.href = `${location.pathname}?${query}` // 跳转到新页面
  }

  // 从 Ajax 载入当前申请所拥有的标签
  self.loadExistingTags = async function () {
    var application_id = self.el.data('application-id')
    var url = `/evaluation/applications/${application_id}/tags`
    var response = await $.ajax({
      url: url,
      type: "GET",
    });
    if (response.status == 'success') {
      if (response.data) {
        var data = response.data
        self.tagify.addTags(data)
        // console.log('标签载入: 成功');
        if (data.length > 0) {
          self.removePlaceHolder();
        }
      }
    } else {
      console.log('标签载入: 失败')
    }
  }

  // 移除 placeholder "直接输入标签或搜索"
  self.removePlaceHolder = function () {
    self.el[0].removeAttribute("placeholder"); // 删掉 dom 属性
    // self.tagify.settings.placeholder = null // 修改 tagify 的数据（但是似乎没用，界面上还是显示 placeholder）
    self.tagify.DOM.input.setAttribute('data-placeholder', '') // 这个做法来自于：https://github.com/yairEO/tagify/issues/562
  }

  // 添加
  self.onAdd = function (e) {
    self.saveTag(e);
    self.removePlaceHolder();
  }

  // 删除
  self.onRemove = function (e) {
    self.saveTag(e);
  }

  // 聚焦
  self.onFocus = function () {
    self.onInput(); // 鼠标聚焦输入框时马上显示自动补全(auto-complete)下拉框
  }

  // 编辑标签 (编辑完之后也触发一次保存)
  self.onEditUpdated = function (e) {
    self.saveTag(e);
  }

  // 保存当前标签
  self.saveTag = function (e) {
    var application_id = self.el.data('application-id')
    var tag_array = []

    if (e.detail.tagify.value) {
      let array = e.detail.tagify.value
      tag_array = array.map(a => a.value);
    }

    // 发 Ajax 保存当前标签
    var data = {
      tag_array: tag_array, // 标签的纯文字
      application_id: application_id,
    }
    $.ajax({
      url: '/evaluation/tags',
      type: "POST",
      data: data,
      success: function (response) {
        console.log(response);
      },
      error: function () {}
    });
  }

  self.init(el);

  return self;
});
