import Plyr from 'plyr';

Ycc.Apply.ApplicationShow = (function(el) {
  var self = this;
  self.init = function(el) {
    self.el = el;
    self.render();
  }

  self.initElements = function() {
    self.player = new Plyr('#apply-player', {
      settings: ['quality'],
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
      quality: { default: 360, options: [360, 720]},
      i18n: {
        qualityLabel: {
          720: "ORIGIN",
          360: "FAST"
        },
        qualityBadge: {
          720: "HD",
          360: ""
        },
        quality: 'quality'
      }
    });
    self.video_modal = self.el.find('[data-role=video_play_modal]');
    self.play_button = self.el.find('[data-role=play]');
  }

  self.render = function() {
    self.initElements();
    self.initEvents();
  };

  self.initEvents = function() {
    // 关闭视频的弹窗，停止播放视频
    self.video_modal.on('hide.bs.modal', () => self.player.pause());
    // 点击打开弹窗
    self.play_button.on('click', () => self.video_modal.modal('toggle'))
  };

  self.init(el);

  return self;
})
