// 标签系统 (主要依靠 Tagify 实现)
Ycc.TaggableCity = (function (el) {
  var self = this;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  self.init = function (el) {
    self.el = el;
    self.render();
  };

  self.render = async function () {
    var element = self.el[0];
    self.tagify = new Tagify(element, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    })
    self.tagify.on('input', self.onInput)
    self.tagify.on('focus', self.onFocus)

  };

  // 聚焦
  self.onFocus = function () {
    self.onInput(); // 鼠标聚焦输入框时马上显示自动补全(auto-complete)下拉框
  }

  // 自动补全
  self.onInput = async function (e) {
    let tagify = self.tagify

    tagify.settings.whitelist.length = 0; // reset current whitelist
    tagify.loading(true) // show the loader animation

    var url = '/companies/autocomplete_cities'
    var search_term = '' // 搜索词（允许为空）

    // 有时会不传参数来调用这个函数，所以这样写
    if (e && e.detail && e.detail.value) {
      search_term = e.detail.value;
    }

    var response = await $.ajax({
      url: url,
      type: "GET",
      data: {
        q: search_term
      }
    });
    if (response.status == 'success') {
      var data = response.data
      tagify.settings.whitelist.push(...data, ...tagify.value)
      await sleep(100);
      tagify.loading(false).dropdown.show.call(tagify, search_term);
    } else {
      tagify.loading(false).dropdown.hide.call(tagify)
    }
  }

  self.init(el);

  return self;
});
