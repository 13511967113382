// 公司类别下的指标

// (本文件的代码全是 Tom Benner 写的，郑诚只是加了些中文注释)
// 业务场景：(build 里) 新建/编辑公司纪录时，有一个"公司类别"，选择了不同的类别，JS 会显示该类别下的指标。
// 具体有什么指标请参考 app/models/company_category.rb
Ycc.CompanyMetricsFields = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function() {
    self.initElements();
    // 初始化元素

    self.initEvents();
    // 初始化事件

    self.onCategoryChange();
    // 监听类别变化
  };

  // 找到元素并保存到变量里
  self.initElements = function() {
    self.categorySelect = self.el.find('select#company_note_category'); // 选类别的那个 <select>
    self.fieldsets = self.el.find('[data-role=company_category_fieldset]');
  };

  // 初始化事件监听
  self.initEvents = function() {
    self.categorySelect.on('change', self.onCategoryChange);
  };

  // 监听到"公司类别"变化时。
  self.onCategoryChange = function() {
    // 这里的逻辑是：根据选择的公司类别，显示该元素。
    var categoryKey = self.categorySelect.val();
    self.fieldsets.hide();
    if (categoryKey) {
      var elementName = '[data-company-category-key=' + categoryKey + ']';
      self.fieldsets.filter(elementName).show();
      // TODO 这里的 bug 是 .show() 没有按预期效果工作。
      // 如果工作正常，比如公司类别选了"软件即服务(SaaS)"，那么这个类别下的 mrr cac gross_revenue_churn 指标输入框就会显示。
      // 但是现在没有。

      // 这个 bug 问过 xuwen 了（2021-12-20）
      // 原话：暂时可以不管他 现在输入的不多
      // 原话：嗯嗯 确实那个流程出来后会好很多，但我们从来没用过，之后用的多了再迭代好了
      // 2021-12-20 结论：现在可以先不管这个 bug，因为也不怎么用。以后等需要的时候再修。
    }
  };

  self.init(el);

  return self;
});
