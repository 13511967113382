// 把 axios 包一层，发请求时在 Header 里带上 Rails 的 CSRF token。
import axios from 'axios'

const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
const ax = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content
    }
  }
})

export default ax