Ycc.Apply.EvaluationForm = (function(el) {
  var self = this;

  self.init = function(el) {
    self.form = el;
    self.render();
  };

  self.render = function() {
    self.initElements();
    self.initEvents();
  };

  self.initElements = function() {
  };

  self.initEvents = function() {
    self.form.on('submit', self.onSubmit);
  };

  self.onSubmit = function(event) {
    event.preventDefault();
    event.stopPropagation();
    // 如果是面试轮/第二轮，优缺点标签 或 评价必填一项
    // console.log(self.form);
    const stage_key = $(event.currentTarget).find('#apply_evaluation_stage_key').attr('value');
    if(stage_key === "interview" || stage_key === "second_review"){
      const positive = $(event.currentTarget).find('#detail_attributes_positive').val();
      const negative = $(event.currentTarget).find('#detail_attributes_negative').val();
      const comment = $(event.currentTarget).find('#apply_evaluation_comment').val();
      if(positive === "[]" && negative === "[]" && comment === ""){
        const node = self.form.find('.error_notification');
        node.find('small').text("请选择优缺点标签或填写评价");
        node.removeClass('display-none').show().delay(5000).slideUp();
        return;
      }
    }

    const buttons = $(event.target).find('input[type=submit]');
    buttons.attr('disabled', 'disabled').data('value', buttons.attr('value')).attr('value', '提交中');
    const button = $(event.originalEvent.submitter);
    $(event.currentTarget).find('#apply_evaluation_submit').val('submit' === button.data('state'));
    $.ajax({
      url: self.form.attr('action') + '.json',
      type: self.form.attr('method'),
      data: self.form.serialize(),
      success: function(_response, _status, xhr) {
        if (self.form.data('in-queue')) {
          if (xhr.status === 200) {
            window.location = self.form.data('redirect-to');
          }
        } else {
          window.location.reload();
        }
        // self.form.replaceWith(response.form_html);
        // // Ycc.renderElements($('[data-role=evaluation_form]'));
        // self.form = $('[data-role=evaluation_form]');
        // self.render();
        // self.form.find('[data-role=evaluation_create_success_notice]').show().delay(2000).slideUp();
        // self.form.find('input[type=submit]').attr('disabled', true).removeClass('btn-primary').addClass('btn-secondary');
        // self.form.on('change', 'input,textarea,select', self.enableSubmitButton);
      },
      error: function(r) {
        const button = self.form.find('input[type=submit]')
        button.removeAttr('disabled').attr('value', button.data('value'));
        const node = self.form.find('.error_notification');
        node.find('small').text(r.responseJSON.message);
        node.removeClass('display-none').show().delay(5000).slideUp();
      }
    });
    return false;
  };

  self.enableSubmitButton = function() {
    self.form.find('input[type=submit]').removeAttr('disabled').removeClass('btn-secondary').addClass('btn-primary');
  };

  self.init(el);

  return self;
});
