document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('[data-role="virtual-link"]').forEach((element) => {

    element.addEventListener('click', function() {
      const href = element.dataset.href
      if (href) {
        window.location.href = href
      }
    })
  })
})
