// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("@rails/activestorage").start()
// This assignment is often done in environment.js, but that prevents the $.fn.select2 function from being registered.
window.$ = window.jQuery = require('jquery')
window.bootbox = require('bootbox')
// daterangepicker depends on moment
window.moment = require('moment')
require('moment-timezone')

// The daterangepicker JS library uses moment's time zone to display times. Because most dates are rendered by the backend, we need to
// ensure that dates on the backend and frontend are rendered in the same time zone. This needs to be set after moment-timezone but prior
// to any DOM-manipulating logic which depends on it.
moment.tz.setDefault('Asia/Singapore');

import 'bootstrap'
import Cookies from "js-cookie"
// import { Carousel } from 'bootstrap'

// Hotfix: 在 require 时自动注册 $.fn.daterangepicker 失效，没有找到原因，所以只能暂时手动注册
const DateRangePicker = require('daterangepicker')
$.fn.daterangepicker = function(options, callback) {
  var implementOptions = $.extend(true, {}, $.fn.daterangepicker.defaultOptions, options);
  this.each(function() {
      var el = $(this);
      if (el.data('daterangepicker'))
          el.data('daterangepicker').remove();
      el.data('daterangepicker', new DateRangePicker(el, implementOptions, callback));
  });
  return this;
}

require('select2')
require('sticky-sidebar')

// 添加对IE浏览器的支持
require('mp_polyfill')

window.Ycc = window.Ycc || {};
window.Ycc.Apply = window.Ycc.Apply || {};
require('lib/apply/application_show')
require('lib/apply/evaluation_form')
require('lib/apply/evaluation_invites_form')
require('lib/apply/founder_fields')
require('lib/apply/private_note')
require('lib/apply/refusal_reason_content')
require('lib/comments_container')
require('lib/company_metrics_fields')
require('lib/currency_toggle')
require('lib/date_picker')
require('lib/investments_group')
require('lib/multi_autocomplete')
require('lib/render_elements')
require('lib/round_form')
require('lib/toggle_link')
require('lib/tag')
require('lib/taggable_city')
require('lib/timeline')
require('lib/video_upload')
require('lib/user_show')
require('lib/autosize')
require('lib/virtual_link')
require('lib/message_auto_close')
require('lib/multiple_images_preview')

// Tagify 用于实现标签系统
// https://github.com/yairEO/tagify
import Tagify from '@yaireo/tagify'
// import '@yaireo/tagify/src/tagify.scss' // 这行代码留着的意义是提示你不要按照 Tagify 里的文档那样直接 import scss
// 在开发环境下这样做没有问题，但是生产环境下会有问题，这个 scss 文件已经复制到 vendor/ 目录去了，已经自动引入了，这里就无需再次引入
window.Tagify = Tagify || {};

// intlTelInput 用于带国家号的手机号码选择 (搜索关键词：phone_token_field)
// https://github.com/jackocnr/intl-tel-input
import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput
window.initIntlTelInput = function(element) {
  let intlTelInput = window.intlTelInput(element, {
    utilsScript: "//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
    nationalMode: false,
    autoHideDialCode: false,
    preferredCountries: ["CN", "HK", "MO", "TW", "US", "CA", "JP", "KR", "UK", "FR", "DE", "SG", "AU"],
    initialCountry: window.Ycc.location || "CN",
    localizedCountries: { 'cn': 'China (中国大陆)', "hk": 'Hong Kong (中國香港)', "mo": 'Macau (中國澳門)', "tw": 'Taiwan (中國台灣)' }
  })

  let label = $(element).parent().find('~ label, ~ .labe');
  $(element).after(label);

  // console.log("Initialized intlTelInput: ", element, intlTelInput)
  return intlTelInput
}

$.ajaxSetup({
  headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
});

$(() => {
  Ycc.renderElements($('body'));

  // bootstrap file input with file name display
  $(".custom-file-input").on("change", function() {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });
});

// 判断是否移动设备
function mp_is_mobile_device(){
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

window.mp_is_mobile_device = mp_is_mobile_device
window.Cookies = Cookies
// window.Carousel = Carousel

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

