Ycc.renderElements = function(el) {
  // If a Select 2 element has already been rendered, we don't want to render a duplicate of it, so we'll remove the existing element.
  // This is especially important in Ycc.InvestmentsGroup.
  el.find('.select2-container').remove();

  // Apply

  el.find('[data-role=evaluation_form]').each(function() {
    new Ycc.Apply.EvaluationForm($(this));
  });
  el.find('[data-role=evaluation_invites_form]').each(function() {
    new Ycc.Apply.EvaluationInvitesForm($(this));
  });
  el.find('[data-role=founder_fields]').each(function() {
    new Ycc.Apply.FounderFields($(this));
  });
  el.find('[data-role=tag_system]').each(function() {
    new Ycc.Tag($(this));
  });
  el.find('[data-role=taggable_city]').each(function() {
    new Ycc.TaggableCity($(this));
  });
  el.find('[data-role=timeline]').each(function() {
    new Ycc.Timeline($(this));
  });
  el.find('.js-private-notes').each(function() {
    new Ycc.Apply.PrivateNote($(this));
  });

  el.find('.js-refusal-reason-content').each(function() {
    new Ycc.Apply.RefusalReasonContent($(this));
  });

  if (el.find('.sticky-sidebar').length > 0) {
    new StickySidebar('.sticky-sidebar', { topSpacing: 10 });
  }

  if (el.find('[data-multiple-img-upload]').length) {
    Ycc.MultipleImagesPreview()
  }

  el.on('apply.daterangepicker', '[data-role=submitted_at_date_range_input]', function() {
    $(this).closest('form').submit();
  });

  // Build

  el.find('.comments-container').each(function() {
    new Ycc.CommentsContainer($(this));
  });
  el.find('[data-role=company_metrics_fields]').each(function() {
    new Ycc.CompanyMetricsFields($(this));
  });
  el.find('[data-role=currency_toggle]').each(function() {
    new Ycc.CurrencyToggle($(this));
  });
  el.find('.date-picker').each(function() {
    new Ycc.DatePicker($(this));
  });
  el.find('[data-role=investments_group]').each(function() {
    new Ycc.InvestmentsGroup($(this));
  });
  el.find('.multi-autocomplete').each(function() {
    new Ycc.MultiAutocomplete($(this));
  });
  el.find('[data-role=round_form]').each(function() {
    new Ycc.RoundForm($(this));
  });
  el.find('#user_show').each(function() {
    new Ycc.UserShow($(this));
  });

  // General

  el.find('[data-role=toggle_link]').each(function() {
    new Ycc.ToggleLink($(this));
  });
  el.find('[data-toggle="tooltip"]').tooltip();

  var currentTz = moment.tz.guess();
  el.find('[data-utc-time]').each(function() {
    var el = $(this);
    var format = el.data('time-format');
    var value = moment(el.data('utc-time')).tz(currentTz).format(format);
    $(this).text(value);
  });

  el.find('input, select, textarea').on('invalid', function() {
    if ($(this).attr('type') === 'email') {
      this.setCustomValidity(Ycc.locale_settings.forms_email_input_notice);
      return;
    }
    if ($(this).attr('required')) {
      this.setCustomValidity(Ycc.locale_settings.forms_required_notice);
    }
  });
  el.find('input, select, textarea').on('input', function(e) {
    this.setCustomValidity('');
  });

  el.find('#video_play').each(function(){
    new Ycc.Apply.ApplicationShow($(this));
  })

  el.find('[data-controller=video_upload]').each(function () {
    new Ycc.Apply.VideoUpload($(this))
  })

  // %button.btn-clipboard(type="button" data-toggle="tooltip" data-placement="top" data-original-title="Copy to clipboard" data-clicked-title="Copied!" data-target="#copy-content")Copy
  // %p#copy-content content
  el.find('.btn-clipboard').on('click', function(){
    const self = $(this)
    const content = $(self.data('target')).text().trim();
    navigator.clipboard.writeText(content).then(() => {
      self.attr("title", self.data('copiedTitle')).tooltip("_fixTitle").tooltip("show")
          .attr("title", self.data('originalTitle')).tooltip("_fixTitle")
    })
  })

  // When rerendering elements, we want to hide any previously active tooltips
  $('.tooltip').tooltip('hide');

  // number-only必须设置maxLength，type可留空或者text即可
  // FIXME: 存在中文输入法输入的问题
  $(".number-only").on("keypress", function(event) {
    const { which, target } = event;
    const { value, maxLength } = target;
    if (which === 8 || which === 46) return;

    const len = value.length;
    if (
      which < 48 ||
      which > 57 ||
      (len === maxLength && target.selectionStart === target.selectionEnd)
    ) {
      event.preventDefault();
    }
  });

  $('[data-select-2]').each(function() {
    let options = $(this).data('select-2');
    if (typeof options != 'object') {
      options = {}
    }
    options['theme'] = 'bootstrap4';
    $(this).select2(options)
  })
};
