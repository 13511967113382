Ycc.ToggleLink = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function() {
    self.el.on('click', function() {
      var selector = self.el.attr('href');
      $(selector).show();
      if (self.el.data('hide-self')) {
        self.el.hide();
      }
      return false;
    });
  };

  self.init(el);

  return self;
});
