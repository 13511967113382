Ycc.RoundForm = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.initElements();
    self.initEvents();
    self.render();
    bootbox.addLocale('current', {
      OK: Ycc.locale_settings.ok,
      // These aren't used but are required by the library
      CANCEL: 'Cancel',
      CONFIRM: 'Confirm'
    });
    bootbox.setLocale('current');
  };

  self.initElements = function() {
    self.totalRaisedRmbInput = self.el.find('#round_total_raised_rmb');
    self.totalRaisedUsdInput = self.el.find('#round_total_raised_usd');
    self.totalRaisedRmbDisplayEl = self.el.find('[data-role=total_raised_rmb]');
    self.totalRaisedUsdDisplayEl = self.el.find('[data-role=total_raised_usd]');
  };

  self.initEvents = function() {
    self.el.on('keyup', '[data-role=amount_input]', self.updateRemaining);
    self.el.on('change', '[data-role=currency_select]', self.updateRemaining);
    self.el.on('investmentsChanged', '[data-role=investments_group]', self.updateRemaining);
    self.el.on('keyup', '#round_total_raised_usd,#round_total_raised_rmb', self.updateRemaining);
    self.el.on('submit', self.validateForm);
  };

  self.render = function() {
    self.updateRemaining();
  };

  self.updateRemaining = function() {
    self.updateRemainingForCurrency('USD');
    self.updateRemainingForCurrency('RMB');
  };

  self.updateRemainingForCurrency = function(currencyUppercase) {
    var currencyLowercase = currencyUppercase.toLowerCase();
    var amounts = self.el.find('[data-role=currency_select] option[value="' + currencyUppercase + '"]:selected').map(function(index, selectEl) {
      var amountInput = $(selectEl).closest('[data-role=investment_item]').find('[data-role=amount_input]');
      return parseFloat(amountInput.val() || 0);
    }).get();
    var amountSum = amounts.length === 0 ? 0 : amounts.reduce(function(a, b) { return a + b; });
    var summaryEl = self.el.find('[data-role=remaining_summary][data-currency=' + currencyLowercase + ']');
    var sumEl = self.el.find('[data-role=sum_' + currencyLowercase + ']');
    sumEl.text(amountSum.toLocaleString('en'));
    summaryEl.toggle(amounts.length > 0);

    var totalRaisedRmb = parseFloat(self.totalRaisedRmbInput.val());
    var totalRaisedUsd = parseFloat(self.totalRaisedUsdInput.val());
    self.totalRaisedRmbDisplayEl.text(totalRaisedRmb ? totalRaisedRmb.toLocaleString('en') : '?');
    self.totalRaisedUsdDisplayEl.text(totalRaisedUsd ? totalRaisedUsd.toLocaleString('en') : '?');

    var sumContainer = summaryEl.find('[data-role=sum-container]');
    sumContainer.removeClass('text-success').removeClass('text-danger');
    var targetSum = currencyUppercase === 'USD' ? totalRaisedUsd : totalRaisedRmb;
    if (amountSum === targetSum) {
      sumContainer.addClass('text-success');
    } else {
      sumContainer.addClass('text-danger');
    }
    self['sum' + currencyUppercase] = amountSum;
  };

  self.validateForm = function() {
    var totalRaisedRmb = parseFloat(self.totalRaisedRmbInput.val());
    var totalRaisedUsd = parseFloat(self.totalRaisedUsdInput.val());
    var isRmbMismatched = self.totalRaisedRmbInput.is(':visible') && totalRaisedRmb !== self['sumRMB'];
    var isUsdMismatched = self.totalRaisedUsdInput.is(':visible') && totalRaisedUsd !== self['sumUSD'];
    if (isRmbMismatched || isUsdMismatched) {
      bootbox.alert(Ycc.locale_settings.rounds_notice_mismatched_amounts);
      return false;
    }
    return true;
  };

  self.init(el);

  return self;
});
