Ycc.Apply.EvaluationInvitesForm = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.listEl = $('[data-role=evaluation_invites_list]');
    self.render();
  };

  self.render = function() {
    self.initElements();
    self.initEvents();
  };

  self.initElements = function() {
  };

  self.initEvents = function() {
    self.el.on('submit', self.onSubmit);
  };

  self.onSubmit = function() {
    $.ajax({
      url: self.el.attr('action'),
      type: self.el.attr('method'),
      data: self.el.serialize(),
      success: function(response) {
        self.el.find('select').val('');
        self.el.find('#apply_evaluation_invite_invitee_id').trigger('change');
        self.listEl.html(response.list_html);
        self.listEl.removeClass('display-none');
      }
    });
    return false;
  };

  self.init(el);

  return self;
});
