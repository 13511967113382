Ycc.MultipleImagesPreview = function() {
  const $uploadLink = $('[data-multiple-img-upload]').find("[data-upload-img-link]")
  const $uploadInput = $('[data-multiple-img-upload]').find("[data-upload-img-input]")
  const $preview = $('[data-multiple-img-upload]').find("[data-preview-area]")
  const templateEle = $('[data-multiple-img-upload]').find("[data-preview-template]")[0]

  $uploadLink.on('click', () => $uploadInput[0].click())

  $uploadInput.on('change', (event) => {
    const files = [...event.target.files]

    files.forEach((file) => {
      const reader = new FileReader()
      const preview = templateEle.content.children[0].cloneNode(true)

      reader.onload = async (e) => {
        const img = preview.querySelector('[data-preview-img]')
        img.src = e.target.result
        $preview.append(preview)

        const newInput = $uploadInput[0].cloneNode()
        const dt = new DataTransfer()
        dt.items.add(file)
        newInput.files = dt.files
        newInput.style.display = 'none'
        newInput.removeAttribute('readonly')
        $(preview).append(newInput)
      }
      reader.readAsDataURL(file)
      return preview
    })

    $uploadInput.val('')
  })

  $(document).on('click', '[data-remove-img-preview]', function() {
    $(this).parents('[data-preview-item]').remove()
  })
}
