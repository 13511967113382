// Tab management is based on http://jsfiddle.net/happysmiles/F33Av/22/

Ycc.Apply.FounderFields = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.tabCounter = self.el.find(".nav-tabs").children().length;
    self.render();
  };

  self.render = function() {
    self.initElements();
    self.initEvents();
    self.refreshTabs();
  };

  self.initElements = function() {
    self.founderFieldsetTemplateEl = self.el.find('[data-role=founder_fieldset_template]');
    self.founderFieldsetTemplateHtml = self.founderFieldsetTemplateEl.html();
    self.founderFieldsetTemplateEl.remove();
    self.initialFounderCounter = parseInt(self.founderFieldsetTemplateHtml.match(/\[founders_attributes\]\[(\d+)\]/)[1]);
    self.founderCounter = self.initialFounderCounter;
  };

  self.initEvents = function() {
    self.el.on('click', '.nav-tabs a', self.onClickTabLink);
    self.el.on('click', '.nav-tabs span.remove-tab', self.onClickRemoveTab);
    self.el.on('click', '.add-tab a', self.onClickAddTab);
  };

  self.onClickTabLink = function(e) {
    e.preventDefault();
    if (!$(this).hasClass('add-tab')) {
      $(this).tab('show');
    }
    setTimeout(() => {
      document.dispatchEvent(new Event('AutosizeTextarea'));
    }, 500)
  };

  self.onClickRemoveTab = function(e) {
    var anchor = $(this).closest('a');
    $(anchor.attr('href')).remove();
    $(this).closest('li').remove();
    self.el.find('.nav-tabs li a .tab-name').first().click();
    self.refreshTabs();

    document.querySelector('.founder-fields').dispatchEvent(new Event('change'));
  };

  self.onClickAddTab = function(e) {
    e.preventDefault();
    self.tabCounter += 1;
    self.founderCounter += 1;
    var tabId = 'founder_' + self.tabCounter;
    var html = `<li class="nav-item" data-role="founder-tab" data-tab-index="${self.tabCounter}">` +
    '<a class="nav-link" href="#' + tabId + '">' +
    '<span class="position-relative">' +
      '<span class="tab-name">New Tab</span>' +
      '<div class="position-absolute d-none" style="top:-11px; right:-8px" data-role="exclamation-icon">' +
        '<i class="fas fa-exclamation-circle text-danger"></i>' +
      '</div>' +
    '</span>' +
    '<span class="remove-tab"><i class="fas fa-times"></i></span>' +
    '</a></li>';
    $(this).closest('li').before(html);

    var templateHtml = self.founderFieldsetTemplateHtml;
    // Replace "apply_application[founders_attributes][3][id]" with "apply_application[founders_attributes][4][id]"
    var pattern = new RegExp('\\[founders_attributes\\]\\[' + self.initialFounderCounter + '\\]', 'g');
    templateHtml = templateHtml.replace(pattern, '[founders_attributes][' + self.founderCounter + ']');
    // Replace "apply_application_founders_attributes_3_id" with "apply_application_founders_attributes_4_id"
    pattern = new RegExp('_founders_attributes_' + self.initialFounderCounter, 'g');
    templateHtml = templateHtml.replace(pattern, '_founders_attributes_' + self.founderCounter);

    self.el.find('.tab-content').append(`<div class="tab-pane fade" id="${tabId}" data-role="founder-${self.tabCounter}-tab-content">${templateHtml}</div>`);
    self.el.find('.nav-tabs li:nth-last-child(2) a').click();
    self.refreshTabs();

    document.querySelector('.founder-fields').dispatchEvent(new Event('change'));
  };

  self.refreshTabs = function() {
    var maxTabs = 5
    var ordinalNumbers = Ycc.locale_settings.ordinal_numbers
    var $addTab = self.el.find('.nav-tabs .add-tab')
    var $funderTabs = self.el.find('.nav-tabs li:not(.add-tab)')

    $funderTabs.each(function(index, el) {
      var el = $(el)
      el.find('.tab-name').text(ordinalNumbers[index])
    })

    if ($funderTabs.length >= maxTabs) {
      $addTab.hide()
    } else {
      $addTab.show()
    }
  };

  self.init(el);

  return self;
});
