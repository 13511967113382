Ycc.InvestmentsGroup = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.investmentCounterEl = el.closest('[data-investment-counter]');
    self.addInvestmentButton = self.el.find('[data-role=add_investment_button]');
    self.investmentsList = self.el.find('[data-role=investments_list]');
    self.initEvents();
    self.render();
  };

  self.initEvents = function() {
    self.addInvestmentButton.on('click', function() {
      var button = $(this);
      var templateSelector = button.data('template-selector');
      var templateHtml = $(templateSelector).html();
      var investmentCounter = self.investmentCounterEl.data('investment-counter');
      investmentCounter += 1;
      self.investmentCounterEl.data('investment-counter', investmentCounter);
      // The template has [0] in the `name` attribute of its fields (e.g., `round[investments_attributes][0][investor_id]`).
      // To avoid collisions of this index in the backend, we'll need to give each rendered template a new index.
      templateHtml = templateHtml.replace(/\[[01]\]/g, '[' + investmentCounter + ']');
      var template = $(templateHtml);
      Ycc.renderElements(template.appendTo(self.investmentsList));
      self.el.trigger('investmentsChanged');
      return false;
    })
    self.el.on('click', '[data-role=remove_investment_button]', function() {
      var button = $(this);
      var investmentItem = button.closest('[data-role=investment_item]');
      investmentItem.remove();
      self.el.trigger('investmentsChanged');
      return false;
    })
  };

  self.render = function() {
  };

  self.init(el);

  return self;
});
