import AWS from 'aws-sdk'
import axios from '@miracleplus/mp_axios'
import {
  v1 as uuidv1
} from 'uuid';
import Plyr from 'plyr';
import fixWebmDuration from 'webm-duration-fix'

var sanitize = require("sanitize-filename");

const MAX_SECOND_PER_VIDEO = 75

class MiraclePlusVideo {
  constructor(file) {
    this.file = file
    this.id = uuidv1();
    this.s3_bucket = null // 上传后的 bucket
    this.s3_key = null // 上传后的 key
    this.download_file_name = null // 下载时的文件名
    this.location = null // 完整的视频链接
  }
}

Ycc.Apply.VideoUpload = (function (el) {
  var self = this;

  self.locales = {
    'zh-CN': {
      progress: '上传进度'
    },
    'en': {
      progress: 'Progress'
    }
  }

  self.init = function (el) {
    self.el = el;
    self.render();
  };

  self.render = function () {
    self.initElements();
    self.initEvents();
    self.initVideoRecord();

    if (self.video_url_text.val() != '') {
      self.video_toggle_upload_way.click()
    }
  };

  self.initElements = function () {
    self.password_key = self.el.data('passwordKey')
    self.key = self.el.data('key')
    self.player = new Plyr('.apply-player[data-key=' + self.key + ']', {
      settings: ['quality'],
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
      quality: {default: 360, options: [360, 720]},
      i18n: {
        qualityLabel: {
          720: "ORIGIN",
          360: "FAST"
        },
        qualityBadge: {
          720: "HD",
          360: ""
        },
        quality: 'quality'
      }
    });
    self.video_modal = self.el.find('[data-role=video_play_modal]')
    // 之前的链接输入框
    self.video_url_text = self.el.find('[data-role=video_url_input]');
    // 之前的密码输入label
    self.video_url_label = $('[data-role=video_url_label][data-key=' + self.password_key + ']');
    // 之前的链接密码输入框
    self.video_password_text = self.video_url_label.find('[data-role=video_password_input]');
    // 切换上传方式按钮
    self.video_toggle_upload_way = self.el.find('[data-role=video_toggle_upload_way]');
    // 视频的play按钮container
    self.video_play_container = self.el.find('[data-role=video-play-container]');
    self.upload_button = self.el.find('[data-role=video_file_input]');
    self.video_file_actions = self.el.find('[data-role=video_file_actions]');
    self.video_file_upload_in_action = self.el.find('[data-role=video_file_upload_in_action]')
    self.new_video_item = self.el.find('[data-role=video]');
    self.hidden_file_input = self.el.find('.hidden_file_input');
    self.progress = self.el.find('[data-role=progress]');
    self.video_data = self.el.find('[data-role=video_data]');
    self.delete_video = self.el.find('[data-role=delete_video]');
    self.play_button = self.el.find('[data-role=play]');

    // 传入的变量
    // 不同的rails env，视频传到不同的bucket
    self.video_settings = self.el.find('[data-role=video_settings]')
    self.rails_env = self.video_settings.data('railsEnv');
    // 中英文文案
    self.size_limit_text = self.video_settings.data('sizeLimit');
    self.duration_limit_text = self.video_settings.data('durationLimit');
    // 当前video问题的下标值
    self.video_index = self.video_settings.data('videoIndex');
    self.video = {};
    self.uploading = false;
    self.has_video = self.player.source;
  };

  self.initEvents = function () {
    // 弹出视频选择
    self.upload_button.on('click', () => self.hidden_file_input.click());
    self.video_file_upload_in_action.on('click', () => self.hidden_file_input.click())
    // 选择完成，则开始上传视频
    self.hidden_file_input.on('change', self.onFileChange);
    // 点击视频删除按钮
    self.delete_video.on('click', self.deleteVideo);
    // 关闭视频的弹窗，停止播放视频
    self.video_modal.on('hide.bs.modal', () => self.player.pause());
    self.play_button.on('click', () => {
      // 上传中无法播放视频
      if (self.uploading) return;
      self.video_modal.modal('toggle');
    })
    self.upload_way = true
    // 直接上传和填写url两种方式切换
    self.video_toggle_upload_way.on('click', (e) => {
      self.upload_way = !self.upload_way;
      var toggle_button = $(e.target);
      if (self.upload_way) {
        toggle_button.text(toggle_button.data('url-content'));
        self.showUploadWay();
      } else {
        toggle_button.text(toggle_button.data('upload-content'));
        self.showUrlWay();
      }
    })
  };

  self.initVideoRecord = () => {
    // init variables
    var $video_file_record_in_action = self.el.find('[data-role=video_file_record_in_action]')

    var $record_video_modal = self.el.find('.record-video-modal')
    var $record_video_modal_dialog = $record_video_modal.find('.modal-dialog')

    var $text_box = $record_video_modal.find('.text-box')
    var $video_box = $record_video_modal.find('.video-box')
    var $spinner = $record_video_modal.find('.spinner-box')

    var $record_video = $record_video_modal.find('.record-video')
    var $preview_video = $record_video_modal.find('.preview-video')

    var $record_actions = $record_video_modal.find('.record-actions')
    var $close_modal_action = $record_video_modal.find('.modal-footer [data-dismiss=modal]')
    var $start_record_action = $record_video_modal.find('[data-action=start_record]')
    var $end_record_action = $record_video_modal.find('[data-action=end_record]')
    var $restart_record_action = $record_video_modal.find('[data-action=restart_record]')
    var $submit_video_action = $record_video_modal.find('[data-action=submit_video]')
    var $message = $record_video_modal.find('[data-role=message]')

    var record_video = $record_video[0]
    var preview_video = $preview_video[0]

    var video_recorder = null
    var video_record_chunks = []
    var video_record_blob = null

    var start_record_time = null
    var is_recording = false

    // 480p:640x480 720p:1280x720 1080p:1920x1080 4k:3840x2160
    var video_record_constraints = {
      audio: {noiseSuppression: false},
      video: {width: {min: 640, ideal: 1920, max: 3840}, height: {min: 480, ideal: 1080, max: 2160}, framerate: 60}
    }

    if (typeof MediaRecorder == 'function' && typeof MediaRecorder.isTypeSupported == 'function') {
      if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
        var video_recorder_options = {mimeType: 'video/webm;codecs=vp9'}
      } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
        var video_recorder_options = {mimeType: 'video/webm;codecs=h264'}
      } else if (MediaRecorder.isTypeSupported('video/webm')) {
        var video_recorder_options = {mimeType: 'video/webm'}
      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        var video_recorder_options = {mimeType: 'video/mp4'}
      }
    } else {
      var video_recorder_options = {}
    }

    // init functions
    var initPreviewVideo = () => {
      var url = URL.createObjectURL(video_record_blob)

      preview_video.src = url

      $preview_video.removeClass('display-none')
      $record_video.addClass('display-none')
    }

    var validateRecordTime = () => {
      var duration = (new Date() - start_record_time) / 1000
      var max_time = MAX_SECOND_PER_VIDEO - 1
      var diff = max_time - duration

      // end record
      if (duration >= max_time) {
        $end_record_action.trigger('click')
      } else if (diff <= 10) {
        $message.removeClass('display-none').html(`${Math.ceil(diff)}s`)
      }
    }

    var initVideoRecorder = (stream) => {
      start_record_time = new Date()
      video_record_chunks = []
      video_record_blob = null
      video_recorder = new MediaRecorder(stream, video_recorder_options)

      video_recorder.ondataavailable = event => {
        if (event.data && event.data.size > 0) {
          video_record_chunks.push(event.data)

          validateRecordTime()
        }
      }

      video_recorder.onstop = async () => {
        is_recording = false

        record_video.pause()
        record_video.srcObject.getTracks().forEach(track => track.stop())
        record_video.srcObject = null

        video_record_blob = new Blob(video_record_chunks, {type: video_recorder_options.mimeType})

        // fix the duration of blob is infinity for chrome
        // detail: https://github.com/buynao/webm-duration-fix
        if (video_recorder_options.mimeType.includes('video/webm')) {
          video_record_blob = await fixWebmDuration(video_record_blob)
        }

        initPreviewVideo()
      }

      is_recording = true
      video_recorder.start(1000)
    }

    var initRecordVideo = (stream, callback) => {
      record_video.srcObject = stream

      record_video.onloadedmetadata = () => {
        callback && callback()

        record_video.play()
        initVideoRecorder(stream)
      }
    }

    // init events
    // 点击录制视频
    $video_file_record_in_action.on('click', () => {
      $record_video_modal.modal()
    })

    // 打开录制视频弹框
    $record_video_modal.on('show.bs.modal', () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder !== undefined) {
        $text_box.addClass('display-none')
        $video_box.removeClass('display-none')
        $record_video_modal_dialog.addClass('modal-lg')
        $record_actions.removeClass('display-none')
      }
    })

    // 关闭录制视频弹框
    $record_video_modal.on('hide.bs.modal', () => {
      if (is_recording) {
        $end_record_action.trigger('click')
      }
    })

    // 点击开始录制按钮
    $start_record_action.on('click', () => {
      $start_record_action.addClass('disabled').blur()
      $spinner.removeClass('display-none')

      navigator.mediaDevices.getUserMedia(video_record_constraints)
        .then(stream => {
          initRecordVideo(stream, () => {
            $message.addClass('display-none').empty()
            $start_record_action.removeClass('disabled').addClass('display-none')
            $close_modal_action.addClass('display-none')
            $end_record_action.removeClass('display-none')
            $spinner.addClass('display-none')
          })
        })
        .catch(error => {
          console.log(`navigator.getUserMedia error: ${error}`)
          $start_record_action.removeClass('disabled')
          $spinner.addClass('display-none')
        })
    })

    // 点击结束录制按钮
    $end_record_action.on('click', () => {
      video_recorder && video_recorder.state !== 'inactive' && video_recorder.stop()

      $message.addClass('display-none').empty()
      $end_record_action.addClass('display-none').blur()
      $restart_record_action.removeClass('display-none')
      $submit_video_action.removeClass('display-none')
    })

    // 点击重新录制按钮
    $restart_record_action.on('click', () => {
      $restart_record_action.addClass('disabled').blur()
      $submit_video_action.addClass('disabled')
      $spinner.removeClass('display-none')

      navigator.mediaDevices.getUserMedia(video_record_constraints)
        .then(stream => {
          initRecordVideo(stream, () => {
            $message.addClass('display-none').empty()
            $restart_record_action.removeClass('disabled').addClass('display-none')
            $submit_video_action.removeClass('disabled').addClass('display-none')
            $end_record_action.removeClass('display-none')
            $preview_video.addClass('display-none')
            $record_video.removeClass('display-none')
            $spinner.addClass('display-none')
          })
        })
        .catch(error => {
          console.log(`navigator.getUserMedia error: ${error}`)
          $restart_record_action.removeClass('disabled')
          $submit_video_action.removeClass('disabled')
          $spinner.addClass('display-none')
        })
    })

    // 点击提交视频按钮
    $submit_video_action.on('click', () => {
      $submit_video_action.addClass('disabled').blur()
      $restart_record_action.addClass('disabled')

      var rand = Math.floor((Math.random() * 10000))
      var ext = video_recorder_options.mimeType == 'video/mp4' ? 'mp4' : 'webm'
      var file = new File([video_record_blob], `video_record_${rand}.${ext}`, {
        type: `video/${ext}`,
        lastModified: new Date().getTime()
      })

      var dataTransfter = new DataTransfer()
      dataTransfter.items.add(file)

      self.hidden_file_input.prop('files', dataTransfter.files)
      self.hidden_file_input.trigger('change')

      $restart_record_action.removeClass('disabled')
      $submit_video_action.removeClass('disabled')
    })
  }

  // 上传的视频删除的按钮
  self.deleteVideo = function (e) {
    // 上传中不能删除
    if (self.uploading) return
    var element = $(e.currentTarget)
    // 隐藏当前可以播放的视频
    element.closest('.item').addClass('display-none');
    // 清理之前上传的数据
    self.cleanVideoData();
    // 显示上传按钮
    self.upload_button.removeClass('display-none');
    self.video_file_actions.removeClass('display-none');
    // 没有视频文件
    self.has_video = false
    if (self.hidden_file_input.data('required')) {
      self.hidden_file_input.prop("required", true)
    }

    // 如果是刚刚上传的视频，那就还没有提交到数据库中，无需给form添加删除视频的id
    if (!element.data('id')) return;
    var video_delete = {
      id: element.data('id'),
      _destroy: true
    }
    // 给form 加入要删除的视频id
    Object.keys(video_delete).forEach(function (key) {
      var value = video_delete[key];

      var x = document.createElement("input");
      x.setAttribute("type", "text");
      x.setAttribute("name", `apply_application[answers_attributes][${self.video_index}][video_attributes][${key}]`);
      x.setAttribute("value", value);

      self.video_data.append(x);
    })
  }

  // 获取上传的bucket名，只是做staging和production的区分
  self.bucketName = function (rails_env) {
    const production_bucket = 'miracleplus-production-videos'
    const staging_bucket = 'miracleplus-staging-videos'
    if (rails_env == 'production') {
      return production_bucket
    } else {
      return staging_bucket
    }
  }

  // 获取上传凭证
  self.getSessionToken = function () {
    return new Promise((resolve, reject) => {
      var url = '/amazon/session_token'
      axios({
        url: url,
        method: 'GET',
        responseType: 'json',
      }).then((response) => {
        resolve(response.data);
      }).catch(e => {
        reject(e);
      })
    });
  }

  // 设置文件上传进度
  self.setFileProgress = function (progress, message) {
    var locale = self.progress.data('locale')

    // error
    if (progress < 0) {
      self.progress.addClass('text-danger').removeClass('text-success').text(`(${message})`)
    } else {
      self.progress.addClass('text-success').removeClass('text-danger')

      if (progress == 100) {
        self.progress.text('')
      } else {
        self.progress.text(`(${self.locales[locale].progress}: ${progress}%)`)
      }
    }
  }

  self.getFileName = function () {
    var file = self.video.file
    var original_file_name = file.name
    var filename_arr = original_file_name.split('.');
    var ext = filename_arr.pop();
    var timeInMs = Date.now(); // 1587186714190
    var new_file_name = `${timeInMs}.${ext}`
    return sanitize(new_file_name)
  }

  // 上传文件
  self.uploadFile = function () {
    self.video.download_file_name = self.getFileName()
    self.startUpload()

    var s3 = null;
    self.getSessionToken().then(function (result) {
      var access_key_id = result.credentials.access_key_id
      var secret_access_key = result.credentials.secret_access_key
      var session_token = result.credentials.session_token

      s3 = new AWS.S3({
        accessKeyId: access_key_id,
        secretAccessKey: secret_access_key,
        sessionToken: session_token,
        region: window.Ycc.location === "US" ? "us-east-1" : 'cn-north-1',
        apiVersion: "2006-03-01",
        correctClockSkew: true,
        maxRetries: 3,
        httpOptions: {
          connectTimeout: 10 * 1000, // 10 seconds
          timeout: 15 * 60 * 1000 // 15 minutes
        }
      });

      var bucket_name = self.bucketName(self.rails_env)
      var Key = `${self.video.id}/${self.video.download_file_name}`

      self.video.s3_bucket = bucket_name
      self.video.s3_key = Key

      s3.upload(
        {
          Bucket: bucket_name,
          Key: Key,
          Body: self.video.file
        },
        {
          partSize: 1024 * 1024 * 10,
          queueSize: 5
        },
        function (err, data) {
          if (err) {
            console.log(err);
            console.log(data);
            self.setFileProgress(-1, err);
            throw "error: " + err + "  data:  " + (data || '');
          }

          console.log('上传完成!');
          console.log(data);
          self.onUploadComplete(data)
          self.setVideoData(data)
          self.hidden_file_input.prop("required", false)
          self.hidden_file_input[0].setCustomValidity("")
          self.hidden_file_input[0].checkValidity()
          self.el.find('.record-video-modal').modal('hide')
        }
      ).on('httpUploadProgress', function (evt) {
        var progress = parseInt((evt.loaded * 100) / evt.total)

        if (progress >= 99) {
          progress = 99
        }
        self.setFileProgress(progress);
      })
    })
  }

  self.startUpload = function () {
    self.new_video_item.removeClass('display-none')
    var new_video_item_play_button = self.new_video_item.find('a.play')
    // 让按钮变为不可点击颜色
    new_video_item_play_button.removeAttr('href')
    // 设置按钮为文件名
    new_video_item_play_button.text(self.video.download_file_name)
    // 删除按钮不可点击了
    self.uploading = true

    self.setFileProgress(0)
  }

  // 显示视频链接输入框和密码输入框以及label, 隐藏上传按钮
  self.showUrlWay = function () {
    //视频链接输入框
    self.video_url_text.removeClass('display-none');
    if (self.video_url_text.data('required')) {
      self.video_url_text.prop("required", true)
    }
    self.video_url_text[0].setCustomValidity("")
    self.video_url_text[0].checkValidity()
    self.video_password_text.removeClass('display-none');

    //密码输入框以及label
    self.video_url_label.removeClass('display-none');

    //上传按钮
    self.upload_button.addClass('display-none');
    self.video_file_actions.addClass('display-none');

    //视频播放区域
    self.video_play_container.addClass('display-none');

    self.hidden_file_input.prop("required", false)
    self.hidden_file_input[0].setCustomValidity("")
    self.hidden_file_input[0].checkValidity()
  }

  // 显示上传按钮, 隐藏视频链接输入框和密码输入框以及label
  self.showUploadWay = function () {
    //视频链接输入框
    self.video_url_text.addClass('display-none');
    self.video_url_text.prop("required", false);
    self.video_url_text.val("");
    self.video_url_text[0].setCustomValidity("")
    self.el.find('[data-role="video-url-field-container"] .invalid-tooltip').html(Ycc.locale_settings.forms_required_notice)
    self.video_url_text[0].checkValidity();
    self.video_password_text.addClass('display-none');

    //密码输入框以及label
    self.video_url_label.addClass('display-none');

    //没有视频文件才显示上传按钮
    if (!self.has_video) {
      self.upload_button.removeClass('display-none')
      self.video_file_actions.removeClass('display-none')
    }

    //视频播放区域
    self.video_play_container.removeClass('display-none');

    if (self.hidden_file_input.data('required')) {
      self.hidden_file_input.prop("required", true)
    }
    self.hidden_file_input[0].setCustomValidity("")
    self.hidden_file_input[0].checkValidity()
  }

  self.onUploadComplete = function (data) {
    // 视频上传完成后，设置视频源
    self.player.source = {type: 'video', sources: [{src: data.Location}]};
    var new_video_item_play_button = self.new_video_item.find('a.play')
    // 变为可点击
    new_video_item_play_button.attr('href', 'javascript:void(0)')
    // 进度设置为 100 %
    self.setFileProgress(100)
    // 删除按钮可以点击了
    self.uploading = false
    // 标识已有视频文件
    self.has_video = true
    // 隐藏上传按钮
    self.upload_button.addClass('display-none')
    self.video_file_actions.addClass('display-none')
  }

  // 视频上传完成后，将视频信息添加到html表单中
  self.setVideoData = function (data) {
    var tmp = {
      aws_s3_bucket: self.video.s3_bucket,
      aws_s3_prefix_key: self.video.id,
      video_names: self.video.download_file_name,
      location_url: data.Location
    }
    // 先清理之前上传的数据
    self.cleanVideoData();
    Object.keys(tmp).forEach(function (key) {
      var value = tmp[key];

      var x = document.createElement("input");
      x.setAttribute("type", "text");
      x.setAttribute("name", `apply_application[answers_attributes][${self.video_index}][video_attributes][${key}]${key == 'video_names' ? "[]" : ""}`)
      x.setAttribute("value", value);

      self.video_data.append(x);
    })
  }

  // 清理上传的数据
  self.cleanVideoData = function () {
    self.video_data.empty()
    self.hidden_file_input.val('')
  }

  // 当文件被修改的时候，进行前端检查，然后上传
  self.onFileChange = function (event) {
    var video_file = null
    if (event.target.files && event.target.files.length > 0) {
      video_file = event.target.files[0];
    }
    if (!video_file) return

    // 检查文件大小
    const MAX_SIZE_PER_FILE_IN_MEGABYTES = 300
    const MAX_SIZE_IN_BYTES = MAX_SIZE_PER_FILE_IN_MEGABYTES * 1024 * 1024
    if (video_file.size > MAX_SIZE_IN_BYTES) {
      event.target.value = ''; // 清空 <input type=file> 里的文件
      const error_text = self.size_limit_text.replace('MAX_SIZE_PER_FILE_IN_MEGABYTES', MAX_SIZE_PER_FILE_IN_MEGABYTES)
      if (self.isWeixin()) {
        self.wxAlert(error_text);
      } else {
        alert(error_text);
      }
      return;
    }

    var video = document.createElement('video');
    video.preload = 'metadata';

    // 微信浏览器需点击播放才能获取视频的时长
    if (self.isWeixin()) {
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        if (duration > MAX_SECOND_PER_VIDEO) {
          // `错误：单个文件最长时长为${MAX_SECOND_PER_VIDEO}秒, 此文件时长为${duration}秒`
          self.wxAlert(self.duration_limit_text.replace('MAX_SECOND_PER_VIDEO', MAX_SECOND_PER_VIDEO).replace('DURATION', duration));
          return;
        }
        // 封装成我们的文件对象
        self.video = new MiraclePlusVideo(video_file)
        // 上传文件, 因为时长的检查是异步的过程，上传放在检查之后
        self.uploadFile()
      }
      video.src = URL.createObjectURL(video_file);
      // 需要微信sdk才能播放视频
      wx.config({
        debug: false,
        appId: "mp_testappId",
        timestamp: 1,
        nonceStr: "",
        signature: "",
        jsApiList: []
      });
      wx.ready(function () {
        video.play();
        video.pause();
      });
    } else {
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        if (duration > MAX_SECOND_PER_VIDEO) {
          alert(self.duration_limit_text.replace('MAX_SECOND_PER_VIDEO', MAX_SECOND_PER_VIDEO).replace('DURATION', duration))
          return;
        }
        // 封装成我们的文件对象
        self.video = new MiraclePlusVideo(video_file)
        // 上传文件, 因为时长的检查是异步的过程，上传放在检查之后
        self.uploadFile()
      }
      video.src = URL.createObjectURL(video_file);
    }
  }

  self.isWeixin = function () {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  }

  // 微信浏览器，在fileOnChange函数中不知为何调用alert不起作用，所以用boostrap的modal来代弹出消息
  self.wxAlert = function (text) {
    $('#wechat_alert .modal-content').text(text)
    $('#wechat_alert').modal('show')
  }

  self.init(el);
  return self;
});
