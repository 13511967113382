// view 里那个 app/views/accelerator/shared/_currency_toggle.html.haml
// 是读取 @company_note 的几个属性，决定 button 是渲染 RMB 还是 USD

// 这里这个 CurrencyToggle 就是单位切换，切换 RMB/USD。
// 页面：build -> 公司管理 -> 选择一家公司 -> 新建纪录
Ycc.CurrencyToggle = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function() {
    self.initElements();
    self.initEvents();
  };

  self.initElements = function() {
    self.textFieldEl = self.el.find('input');
    self.rmbButton = self.el.find('button[data-currency=RMB]');
    self.usdButton = self.el.find('button[data-currency=USD]');
  };

  self.initEvents = function() {
    self.rmbButton.on('click', function() {
      self.rmbButton.hide();
      self.usdButton.show();
      self.usdButton.removeClass('display-none');
      // 2021-12-23 修了一下 bug：show()没有成功
      // class 里那个 display-none 依旧生效
      // 所以隐藏 RMB 符号的时候，USD 符号没有按预期显示出来。把 class 删掉就行了。
      self.textFieldEl.val('USD');
      return false;
    });
    self.usdButton.on('click', function() {
      self.usdButton.hide();
      self.rmbButton.show();
      self.textFieldEl.val('RMB');
      return false;
    });
  };

  self.init(el);

  return self;
});
