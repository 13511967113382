import autosize from 'autosize/dist/autosize.min';
Ycc.Apply.PrivateNote = (function(el) {
  const createPrivateNoteUrl = '/private_notes';
  const updatePrivateNoteUrl = '/private_notes/';
  const application_id = el.data('id');
  const list_content = el.find('.js-list-private-notes');
  const new_private_note_form = el.find('.js-new-private-note-form');
  // config toast
  const toast_element = el.find('.toast')
  toast_element.toast({ animation: true, delay: 2000 })

  const toast = function(message, type) {
    if (type=='error') { toast_element.find('i.fas').addClass('.text-danger') }
    toast_element.find('strong').text(message)
    toast_element.toast('show');
  }

  // 触发autosize
  const triggerAutoSize = function() {
    const textareas = document.querySelectorAll('.autosize-textarea');
    textareas.forEach(el => el.dispatchEvent(new Event('autosize:update')));
  }

  const createPrivateNote = function(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = $(event.target);
    const content = form.find('.js-content').val();
 
    $.ajax({
      url: createPrivateNoteUrl,
      type: 'POST',
      data: { private_note: { application_id, content } },
      success: function(response, _status, xhr) {
        if (xhr.status === 200) {
          const note_id = response.id
          addNote(note_id, content);
          form.find('.js-content').val('');
          toast('创建成功');
        }
      },
      error: function(response) {
        toast(response.message, 'error');
      }
    });
  }

  const updatePrivateNote = function(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = $(event.target).closest('form');
    const content = form.find('.js-content').val();
    const note_id = form.data('note-id');
 
    $.ajax({
      url: updatePrivateNoteUrl + note_id,
      type: 'PUT',
      data: { note_id, private_note: { content } },
      success: function(response, _status, xhr) {
        if (xhr.status === 200) {
          form.find('.js-text-container small').text(content);
          form.find('.js-cancel-button').click();
          toast('更新成功');
        }
      },
      error: function(r) {
        toast(response.message, 'error');
      }
    })
  }

  const deletePrivateNote = function(event) {
    const form = $(event.target).closest('form');
    const note_id = form.data('note-id');
    if (confirm('确认删除该记录?')) {
      $.ajax({
        url: updatePrivateNoteUrl + note_id,
        type: 'delete',
        data: { note_id },
        success: function(response, _status, xhr) {
          if (xhr.status === 200) {
            deleteNote(note_id);
            toast('删除成功');
          }
        },
        error: function(r) {
          toast(response.message, 'error');
        }
      })
    }
  }

  const deleteNote = function(note_id) {
    list_content.find('.js-note-id-' + note_id).remove();
    new_private_note_form.removeClass('display-none');
    triggerAutoSize();
  }

  const addNote = function(note_id, note_content) {
    list_content.append(renderNote(note_id, note_content));
    $(`.js-note-id-${note_id} .js-content`).val(note_content);
    $(`.js-note-id-${note_id}`).data('note', { id: note_id });
    addListEvent();
    new_private_note_form.addClass('display-none');
  }

  const renderNote = function(note_id, note_content) {
    const template = $('.js-note-template');
    const note_template = $(template.clone().prop('outerHTML'));
    note_template.attr('data-note-id', note_id);
    note_template.find('.js-content').val(note_content);
    note_template.find('.js-text-container small').text(note_content);
    note_template.removeClass('js-note-template display-none');
    note_template.addClass(`js-note-id-${note_id}`);
    return note_template.prop('outerHTML');
  }

  const addListEvent = function() {
    list_content.find('form .js-submit-button').on('click', updatePrivateNote);
    list_content.find('form .js-delete-icon').off().on('click', deletePrivateNote);
    // 这里只是为了给新加的textarea添加autosize
    autosize(document.querySelectorAll('.autosize-textarea'));

    // 编辑弹出修改
    list_content.find('.js-edit-icon').on('click', function(event) {
      const form = $(event.target).closest('form');
      form.find('.js-input-container').removeClass('display-none');
      form.find('.js-text-container').addClass('display-none');
      triggerAutoSize();
    })

    // 取消隐藏修改
    list_content.find('.js-cancel-button').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();

      const form = $(event.target).closest('form');
      form.find('.js-input-container').addClass('display-none');
      form.find('.js-text-container').removeClass('display-none');
    })
  }
  new_private_note_form.on('submit', createPrivateNote);
  addListEvent();
})