// apply 的用户个人主页会用到此文件
// 比如 http://localhost:3000/admin/users/2
// 用途：复制项目推荐链接到粘贴板
import QRCode from 'qrcode';

Ycc.UserShow = function (el) {
  var self = this;

  self.init = function (el) {
    self.el = el;
    self.link = self.el.find('#source_link').text();
    self.ss_link = self.el.find('#source_ss_link').text()
    self.render();
    self.ss_render();

  };

  self.render = function () {
    self.el.find('#copy_btn').on('click', {'link': self.link}, self.copyToClipBoard);
    self.renderQrcode('canvas', self.link)
  };

  self.ss_render = function () {
    self.el.find('#copy_ss_btn').on('click', {'link': self.ss_link}, self.copyToClipBoard);
    self.renderQrcode('ss_canvas', self.ss_link)
  };

  // 复制项目推荐链接到粘贴板
  self.copyToClipBoard = function (e) {
    navigator.clipboard.writeText(e.data['link'])
      .then(() => {
        bootbox.alert('链接已复制到剪贴板');
      }).catch(err => {
        bootbox.alert('链接复制失败');
      })
  };

  self.renderQrcode = function (id, link) {
    var canvas = document.getElementById(id);
    QRCode.toCanvas(canvas, link)
  }

  self.init(el);

  return self;
};
