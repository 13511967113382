// 申请首页时间线
Ycc.Timeline = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    self.render();
  };

  self.render = function(){
    window.el = self.el
    const dates = self.el.data('dates').map((data) => {
      const date = moment.tz(data['date'], "Asia/Shanghai")
      return { ...data, date }
    }).sort(function(a, b) {
      return a.date - b.date
    })
    self.locale = self.el.data('locale') || 'zh-CN'
    self.makeCircles(dates)
    self.addClickEvent()
  }

  self.addClickEvent = function(){
    $('.circle').click((e)=> {
      $('.circle').removeClass('active')
      $(e.target).closest('.circle').addClass('active')
    })
  }

  //Main function. Draw circles.
  self.makeCircles = function makeCircles(dates) {
    const firstDate = dates[0]['date'];
    const lastDate = dates[dates.length - 1]['date'];
    const lastInt = lastDate.diff(firstDate, 'day');

    const line = self.el.find(".line");
    const timezoneStr = { 'zh-CN': '北京时间', 'en': 'Beijing Time' }[self.locale]
    const localeClass = { 'zh-CN': '', 'en': 'en' }[self.locale]

    // 先计算出每个时间点的位置，放入relativeInt
    dates.forEach((date, index) => {
      const thisInt = - firstDate.diff(date['date'], 'day')
      dates[index]['relativeInt'] = thisInt / lastInt;
    })

    // 逆向遍历时间点的位置，如果离得太近，小于0.26，设定位置为下一个点的百分比减0.26
    // 使用逆向是因为重叠只会发生在时间轴的后面，前面离得太远，不会影响
    Array.from(Array(dates.length).keys()).reverse().forEach((index) => {
      if (index == dates.length - 1 || index == 0 ) { return }
      let closestData = null;

      // 计算时需跳过当前时间
      if (dates[index + 1]['key'] == 'current_time') {
        closestData = dates[index + 2] && dates[index + 2]['relativeInt']
      } else {
        closestData = dates[index + 1]['relativeInt']
      }
      if (closestData - dates[index]['relativeInt'] < 0.26) {
        dates[index]['relativeInt'] = closestData - 0.26
      }
    })

    dates.forEach((date, index) => {
      const relativeInt = date['relativeInt'];
      const isCurrentTime = date['key'] == 'current_time';
      const isApplicationEnd = date['key'] == 'application_end_time';

      line.append('<div class="circle ' + (isCurrentTime ? 'active' : '') + '" style="left: ' + relativeInt * 100 + '%;"><div class="circle_info ' + localeClass+ (isCurrentTime ? ' current' : '') +'"><div class="date">' + (isCurrentTime ? date['text'] : date['date'].format('YYYY-MM-DD')) + '</div><div class="desc">' + (isCurrentTime ? '' : date['text']) + '</div><div class="timezone">'+ (isApplicationEnd ? timezoneStr : '') +'</div></div></div>');
    })

    $(".active_circle").addClass("active");
  }

  self.init(el);

  return self;
})
