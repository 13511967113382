$(() => {
  // 如果页面有falsh message, 5s后自动关闭
  const message_alerts = $('.message-container .message-alert')

  if (message_alerts.length > 0) {
    setTimeout(() => {
      message_alerts.each(function() {
        $(this).find('.close').click()
      })
    }, 5000)
  }
})
